@import "https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap";
.mdl-button {
  color: #000;
  height: 36px;
  min-width: 64px;
  text-transform: uppercase;
  letter-spacing: 0;
  will-change: box-shadow;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  background: none;
  border: none;
  border-radius: 2px;
  outline: 0;
  margin: 0;
  padding: 0 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  text-decoration: none;
  transition: box-shadow .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, .2, 1), color .2s cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.mdl-button::-moz-focus-inner {
  border: 0;
}

.mdl-button:hover {
  background-color: #9e9e9e33;
}

.mdl-button:focus:not(:active) {
  background-color: #0000001f;
}

.mdl-button:active {
  background-color: #9e9e9e66;
}

.mdl-button.mdl-button--colored {
  color: #3f51b5;
}

.mdl-button.mdl-button--colored:focus:not(:active) {
  background-color: #0000001f;
}

input.mdl-button[type="submit"] {
  -webkit-appearance: none;
}

.mdl-button--raised {
  background: #9e9e9e33;
  box-shadow: 0 2px 2px #00000024, 0 3px 1px -2px #0003, 0 1px 5px #0000001f;
}

.mdl-button--raised:active {
  background-color: #9e9e9e66;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;
}

.mdl-button--raised:focus:not(:active) {
  background-color: #9e9e9e66;
  box-shadow: 0 0 8px #0000002e, 0 8px 16px #0000005c;
}

.mdl-button--raised.mdl-button--colored {
  color: #fff;
  background: #3f51b5;
}

.mdl-button--raised.mdl-button--colored:hover, .mdl-button--raised.mdl-button--colored:active, .mdl-button--raised.mdl-button--colored:focus:not(:active) {
  background-color: #3f51b5;
}

.mdl-button--raised.mdl-button--colored .mdl-ripple {
  background: #fff;
}

.mdl-button--fab {
  height: 56px;
  min-width: 56px;
  width: 56px;
  background: #9e9e9e33;
  border-radius: 50%;
  margin: auto;
  padding: 0;
  font-size: 24px;
  line-height: normal;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 1.5px #0000001f, 0 1px 1px #0000003d;
}

.mdl-button--fab .material-icons {
  width: 24px;
  line-height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12px, -12px);
}

.mdl-button--fab.mdl-button--mini-fab {
  height: 40px;
  min-width: 40px;
  width: 40px;
}

.mdl-button--fab .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-button--fab:active {
  background-color: #9e9e9e66;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;
}

.mdl-button--fab:focus:not(:active) {
  background-color: #9e9e9e66;
  box-shadow: 0 0 8px #0000002e, 0 8px 16px #0000005c;
}

.mdl-button--fab.mdl-button--colored {
  color: #fff;
  background: #ff4081;
}

.mdl-button--fab.mdl-button--colored:hover, .mdl-button--fab.mdl-button--colored:focus:not(:active), .mdl-button--fab.mdl-button--colored:active {
  background-color: #ff4081;
}

.mdl-button--fab.mdl-button--colored .mdl-ripple {
  background: #fff;
}

.mdl-button--icon {
  height: 32px;
  min-width: 32px;
  width: 32px;
  color: inherit;
  border-radius: 50%;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  font-size: 24px;
  line-height: normal;
  overflow: hidden;
}

.mdl-button--icon .material-icons {
  width: 24px;
  line-height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12px, -12px);
}

.mdl-button--icon.mdl-button--mini-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
}

.mdl-button--icon.mdl-button--mini-icon .material-icons {
  top: 0;
  left: 0;
}

.mdl-button--icon .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
}

.mdl-button__ripple-container {
  height: 100%;
  width: 100%;
  z-index: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple, .mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple {
  background-color: #0000;
}

.mdl-button--primary.mdl-button--primary {
  color: #3f51b5;
}

.mdl-button--primary.mdl-button--primary .mdl-ripple {
  background: #fff;
}

.mdl-button--primary.mdl-button--primary.mdl-button--fab, .mdl-button--primary.mdl-button--primary.mdl-button--raised {
  color: #fff;
  background-color: #3f51b5;
}

.mdl-button--accent.mdl-button--accent {
  color: #ff4081;
}

.mdl-button--accent.mdl-button--accent .mdl-ripple {
  background: #fff;
}

.mdl-button--accent.mdl-button--accent.mdl-button--fab, .mdl-button--accent.mdl-button--accent.mdl-button--raised {
  color: #fff;
  background-color: #ff4081;
}

.mdl-button.mdl-button--disabled.mdl-button--disabled, .mdl-button[disabled][disabled] {
  color: #00000042;
  cursor: default;
  background-color: #0000;
}

.mdl-button--fab.mdl-button--disabled.mdl-button--disabled, .mdl-button--fab[disabled][disabled] {
  color: #00000042;
  background-color: #0000001f;
}

.mdl-button--raised.mdl-button--disabled.mdl-button--disabled, .mdl-button--raised[disabled][disabled] {
  color: #00000042;
  box-shadow: none;
  background-color: #0000001f;
}

.mdl-button--colored.mdl-button--disabled.mdl-button--disabled, .mdl-button--colored[disabled][disabled] {
  color: #00000042;
}

.mdl-button .material-icons {
  vertical-align: middle;
}

.mdl-card {
  min-height: 200px;
  width: 330px;
  z-index: 1;
  box-sizing: border-box;
  background: #fff;
  border-radius: 2px;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  position: relative;
  overflow: hidden;
}

.mdl-card__media {
  box-sizing: border-box;
  background-color: #ff4081;
  background-position: 50%;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
}

.mdl-card__title {
  color: #000;
  perspective-origin: 165px 56px;
  transform-origin: 165px 56px;
  box-sizing: border-box;
  justify-content: stretch;
  align-items: center;
  padding: 16px;
  line-height: normal;
  display: flex;
}

.mdl-card__title.mdl-card--border {
  border-bottom: 1px solid #0000001a;
}

.mdl-card__title-text {
  color: inherit;
  transform-origin: 149px 48px;
  align-self: flex-end;
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  display: flex;
  overflow: hidden;
}

.mdl-card__subtitle-text {
  color: #0000008a;
  margin: 0;
  font-size: 14px;
}

.mdl-card__supporting-text {
  color: #0000008a;
  width: 90%;
  padding: 16px;
  font-size: 1rem;
  line-height: 18px;
  overflow: hidden;
}

.mdl-card__supporting-text.mdl-card--border {
  border-bottom: 1px solid #0000001a;
}

.mdl-card__actions {
  width: 100%;
  box-sizing: border-box;
  background-color: #0000;
  padding: 8px;
  font-size: 16px;
  line-height: normal;
}

.mdl-card__actions.mdl-card--border {
  border-top: 1px solid #0000001a;
}

.mdl-card--expand {
  flex-grow: 1;
}

.mdl-card__menu {
  position: absolute;
  top: 16px;
  right: 16px;
}

.mdl-dialog {
  width: 280px;
  border: none;
  box-shadow: 0 9px 46px 8px #00000024, 0 11px 15px -7px #0000001f, 0 24px 38px 3px #0003;
}

.mdl-dialog__title {
  margin: 0;
  padding: 24px 24px 0;
  font-size: 2.5rem;
}

.mdl-dialog__actions {
  flex-flow: row-reverse wrap;
  padding: 8px 8px 8px 24px;
  display: flex;
}

.mdl-dialog__actions > * {
  height: 36px;
  margin-right: 8px;
}

.mdl-dialog__actions > :first-child {
  margin-right: 0;
}

.mdl-dialog__actions--full-width {
  padding: 0 0 8px;
}

.mdl-dialog__actions--full-width > * {
  height: 48px;
  text-align: right;
  flex: 0 0 100%;
  margin-right: 0;
  padding-right: 16px;
}

.mdl-dialog__content {
  color: #0000008a;
  padding: 20px 24px 24px;
}

.mdl-progress {
  height: 4px;
  width: 500px;
  max-width: 100%;
  display: block;
  position: relative;
}

.mdl-progress > .bar {
  width: 0%;
  transition: width .2s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
}

.mdl-progress > .progressbar {
  z-index: 1;
  background-color: #3f51b5;
  left: 0;
}

.mdl-progress > .bufferbar {
  z-index: 0;
  background-image: linear-gradient(to right, #ffffffb3, #ffffffb3), linear-gradient(to right, #3f51b5, #3f51b5);
  left: 0;
}

.mdl-progress > .auxbar {
  right: 0;
}

@supports ((-webkit-appearance: none)) {
  .mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate) > .auxbar, .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate) > .auxbar {
    background-image: linear-gradient(to right, #ffffffb3, #ffffffb3), linear-gradient(to right, #3f51b5, #3f51b5);
    -webkit-mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=");
    mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=");
  }
}

.mdl-progress:not(.mdl-progress--indeterminate) > .auxbar, .mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
  background-image: linear-gradient(to right, #ffffffe6, #ffffffe6), linear-gradient(to right, #3f51b5, #3f51b5);
}

.mdl-progress.mdl-progress--indeterminate > .bar1, .mdl-progress.mdl-progress__indeterminate > .bar1 {
  background-color: #3f51b5;
  animation-name: indeterminate1;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.mdl-progress.mdl-progress--indeterminate > .bar3, .mdl-progress.mdl-progress__indeterminate > .bar3 {
  background-color: #3f51b5;
  background-image: none;
  animation-name: indeterminate2;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes indeterminate1 {
  0% {
    width: 0%;
    left: 0;
  }

  50% {
    width: 75%;
    left: 25%;
  }

  75% {
    width: 0%;
    left: 100%;
  }
}

@keyframes indeterminate2 {
  0% {
    width: 0%;
    left: 0;
  }

  50% {
    width: 0%;
    left: 0;
  }

  75% {
    width: 25%;
    left: 0;
  }

  100% {
    width: 0%;
    left: 100%;
  }
}

.mdl-shadow--2dp {
  box-shadow: 0 2px 2px #00000024, 0 3px 1px -2px #0003, 0 1px 5px #0000001f;
}

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px #00000024, 0 3px 3px -2px #0003, 0 1px 8px #0000001f;
}

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;
}

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px #00000024, 0 1px 18px #0000001f, 0 3px 5px -1px #0003;
}

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f, 0 5px 5px -3px #0003;
}

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f, 0 8px 10px -5px #0003;
}

.mdl-shadow--24dp {
  box-shadow: 0 9px 46px 8px #00000024, 0 11px 15px -7px #0000001f, 0 24px 38px 3px #0003;
}

.mdl-spinner {
  width: 28px;
  height: 28px;
  display: inline-block;
  position: relative;
}

.mdl-spinner:not(.is-upgraded).is-active:after {
  content: "Loading...";
}

.mdl-spinner.is-upgraded.is-active {
  animation: 1.568s linear infinite mdl-spinner__container-rotate;
}

@keyframes mdl-spinner__container-rotate {
  to {
    transform: rotate(360deg);
  }
}

.mdl-spinner__layer {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}

.mdl-spinner__layer-1 {
  border-color: #42a5f5;
}

.mdl-spinner--single-color .mdl-spinner__layer-1 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-1 {
  animation: 5.332s cubic-bezier(.4, 0, .2, 1) infinite both mdl-spinner__fill-unfill-rotate, 5.332s cubic-bezier(.4, 0, .2, 1) infinite both mdl-spinner__layer-1-fade-in-out;
}

.mdl-spinner__layer-2 {
  border-color: #f44336;
}

.mdl-spinner--single-color .mdl-spinner__layer-2 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-2 {
  animation: 5.332s cubic-bezier(.4, 0, .2, 1) infinite both mdl-spinner__fill-unfill-rotate, 5.332s cubic-bezier(.4, 0, .2, 1) infinite both mdl-spinner__layer-2-fade-in-out;
}

.mdl-spinner__layer-3 {
  border-color: #fdd835;
}

.mdl-spinner--single-color .mdl-spinner__layer-3 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-3 {
  animation: 5.332s cubic-bezier(.4, 0, .2, 1) infinite both mdl-spinner__fill-unfill-rotate, 5.332s cubic-bezier(.4, 0, .2, 1) infinite both mdl-spinner__layer-3-fade-in-out;
}

.mdl-spinner__layer-4 {
  border-color: #4caf50;
}

.mdl-spinner--single-color .mdl-spinner__layer-4 {
  border-color: #3f51b5;
}

.mdl-spinner.is-active .mdl-spinner__layer-4 {
  animation: 5.332s cubic-bezier(.4, 0, .2, 1) infinite both mdl-spinner__fill-unfill-rotate, 5.332s cubic-bezier(.4, 0, .2, 1) infinite both mdl-spinner__layer-4-fade-in-out;
}

@keyframes mdl-spinner__fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  to {
    transform: rotate(1080deg);
  }
}

@keyframes mdl-spinner__layer-1-fade-in-out {
  from {
    opacity: .99;
  }

  25% {
    opacity: .99;
  }

  26% {
    opacity: 0;
  }

  89% {
    opacity: 0;
  }

  90% {
    opacity: .99;
  }

  100% {
    opacity: .99;
  }
}

@keyframes mdl-spinner__layer-2-fade-in-out {
  from {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  25% {
    opacity: .99;
  }

  50% {
    opacity: .99;
  }

  51% {
    opacity: 0;
  }
}

@keyframes mdl-spinner__layer-3-fade-in-out {
  from {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  50% {
    opacity: .99;
  }

  75% {
    opacity: .99;
  }

  76% {
    opacity: 0;
  }
}

@keyframes mdl-spinner__layer-4-fade-in-out {
  from {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  75% {
    opacity: .99;
  }

  90% {
    opacity: .99;
  }

  100% {
    opacity: 0;
  }
}

.mdl-spinner__gap-patch {
  box-sizing: border-box;
  width: 10%;
  height: 100%;
  border-color: inherit;
  position: absolute;
  top: 0;
  left: 45%;
  overflow: hidden;
}

.mdl-spinner__gap-patch .mdl-spinner__circle {
  width: 1000%;
  left: -450%;
}

.mdl-spinner__circle-clipper {
  width: 50%;
  height: 100%;
  border-color: inherit;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.mdl-spinner__circle-clipper.mdl-spinner__left {
  float: left;
}

.mdl-spinner__circle-clipper.mdl-spinner__right {
  float: right;
}

.mdl-spinner__circle-clipper .mdl-spinner__circle {
  width: 200%;
}

.mdl-spinner__circle {
  box-sizing: border-box;
  height: 100%;
  border-style: solid;
  border-width: 3px;
  border-color: inherit;
  border-radius: 50%;
  animation: none;
  position: absolute;
  inset: 0;
  border-bottom-color: #0000 !important;
}

.mdl-spinner__left .mdl-spinner__circle {
  transform: rotate(129deg);
  border-right-color: #0000 !important;
}

.mdl-spinner.is-active .mdl-spinner__left .mdl-spinner__circle {
  animation: 1.333s cubic-bezier(.4, 0, .2, 1) infinite both mdl-spinner__left-spin;
}

.mdl-spinner__right .mdl-spinner__circle {
  left: -100%;
  transform: rotate(-129deg);
  border-left-color: #0000 !important;
}

.mdl-spinner.is-active .mdl-spinner__right .mdl-spinner__circle {
  animation: 1.333s cubic-bezier(.4, 0, .2, 1) infinite both mdl-spinner__right-spin;
}

@keyframes mdl-spinner__left-spin {
  from {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(130deg);
  }
}

@keyframes mdl-spinner__right-spin {
  from {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }

  to {
    transform: rotate(-130deg);
  }
}

.mdl-textfield {
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: 20px 0;
  font-size: 16px;
  display: inline-block;
  position: relative;
}

.mdl-textfield .mdl-button {
  position: absolute;
  bottom: 20px;
}

.mdl-textfield--align-right {
  text-align: right;
}

.mdl-textfield--full-width {
  width: 100%;
}

.mdl-textfield--expandable {
  min-width: 32px;
  width: auto;
  min-height: 32px;
}

.mdl-textfield--expandable .mdl-button--icon {
  top: 16px;
}

.mdl-textfield__input {
  width: 100%;
  text-align: left;
  color: inherit;
  background: none;
  border: none;
  border-bottom: 1px solid #0000001f;
  margin: 0;
  padding: 4px 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  display: block;
}

.mdl-textfield__input[type="number"] {
  -moz-appearance: textfield;
}

.mdl-textfield__input[type="number"]::-webkit-inner-spin-button, .mdl-textfield__input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mdl-textfield.is-focused .mdl-textfield__input {
  outline: 0;
}

.mdl-textfield.is-invalid .mdl-textfield__input {
  box-shadow: none;
  border-color: #d50000;
}

.mdl-textfield.is-disabled .mdl-textfield__input, fieldset[disabled] .mdl-textfield .mdl-textfield__input {
  color: #00000042;
  background-color: #0000;
  border-bottom: 1px dotted #0000001f;
}

.mdl-textfield textarea.mdl-textfield__input {
  display: block;
}

.mdl-textfield__label {
  color: #00000042;
  pointer-events: none;
  width: 100%;
  white-space: nowrap;
  text-align: left;
  font-size: 16px;
  display: block;
  position: absolute;
  inset: 24px 0 0;
  overflow: hidden;
}

.mdl-textfield.has-placeholder .mdl-textfield__label, .mdl-textfield.is-dirty .mdl-textfield__label {
  visibility: hidden;
}

.mdl-textfield--floating-label .mdl-textfield__label {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  transition: none;
}

.mdl-textfield.is-disabled.is-disabled .mdl-textfield__label, fieldset[disabled] .mdl-textfield .mdl-textfield__label {
  color: #00000042;
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__label, .mdl-textfield--floating-label.is-focused .mdl-textfield__label {
  color: #3f51b5;
  visibility: visible;
  font-size: 12px;
  top: 4px;
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__expandable-holder .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder .mdl-textfield__label, .mdl-textfield--floating-label.is-focused .mdl-textfield__expandable-holder .mdl-textfield__label {
  top: -16px;
}

.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
  color: #d50000;
  font-size: 12px;
}

.mdl-textfield__label:after {
  content: "";
  height: 2px;
  visibility: hidden;
  width: 10px;
  background-color: #3f51b5;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  bottom: 20px;
  left: 45%;
}

.mdl-textfield.is-focused .mdl-textfield__label:after {
  visibility: visible;
  width: 100%;
  left: 0;
}

.mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: #d50000;
}

.mdl-textfield__error {
  color: #d50000;
  visibility: hidden;
  margin-top: 3px;
  font-size: 12px;
  display: block;
  position: absolute;
}

.mdl-textfield.is-invalid .mdl-textfield__error {
  visibility: visible;
}

.mdl-textfield__expandable-holder {
  max-width: .1px;
  margin-left: 32px;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
  position: relative;
}

.mdl-textfield.is-dirty .mdl-textfield__expandable-holder, .mdl-textfield.is-focused .mdl-textfield__expandable-holder {
  max-width: 600px;
}

.mdl-textfield__expandable-holder .mdl-textfield__label:after {
  bottom: 0;
}

dialog {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: #000;
  background: #fff;
  border: solid;
  margin: auto;
  padding: 1em;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

dialog:not([open]) {
  display: none;
}

dialog + .backdrop {
  background: #0000001a;
  position: fixed;
  inset: 0;
}

._dialog_overlay {
  position: fixed;
  inset: 0;
}

dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}

.firebaseui-container {
  box-sizing: border-box;
  color: #000000de;
  direction: ltr;
  max-width: 360px;
  text-align: left;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  font: 16px Roboto, arial, sans-serif;
  position: relative;
  overflow: visible;
}

.firebaseui-container.mdl-card {
  overflow: visible;
}

.firebaseui-card-header {
  padding: 24px 24px 0;
}

.firebaseui-card-content, .firebaseui-card-footer {
  padding: 0 24px;
}

.firebaseui-card-actions {
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 8px 24px 24px;
  font-size: 14px;
  display: table;
}

.firebaseui-form-links {
  vertical-align: middle;
  width: 100%;
  display: table-cell;
}

.firebaseui-form-actions {
  text-align: right;
  white-space: nowrap;
  width: 100%;
  display: table-cell;
}

.firebaseui-subtitle, .firebaseui-title {
  color: #000000de;
  direction: ltr;
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.firebaseui-title {
  padding-bottom: 16px;
}

.firebaseui-subtitle {
  margin: 16px 0;
}

.firebaseui-text {
  color: #000000de;
  direction: ltr;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
}

.firebaseui-id-page-password-recovery-email-sent p.firebaseui-text {
  margin: 16px 0;
}

.firebaseui-text-emphasis {
  font-weight: 700;
}

.firebaseui-error {
  color: #dd2c00;
  direction: ltr;
  text-align: left;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
}

.firebaseui-text-input-error {
  margin: -16px 0 16px;
}

.firebaseui-error-wrapper {
  min-height: 16px;
}

.firebaseui-list-item {
  direction: ltr;
  text-align: left;
  margin: 0;
  padding: 0;
}

.firebaseui-hidden {
  display: none;
}

.firebaseui-relative-wrapper {
  position: relative;
}

.firebaseui-label {
  color: #0000008a;
  direction: ltr;
  text-align: left;
  font-size: 16px;
}

.mdl-textfield--floating-label.is-dirty .mdl-textfield__label, .mdl-textfield--floating-label.is-focused .mdl-textfield__label {
  color: #757575;
}

.firebaseui-input, .firebaseui-input-invalid {
  color: #000000de;
  direction: ltr;
  width: 100%;
  border-radius: 0;
  font-size: 16px;
}

input.firebaseui-input, input.firebaseui-input-invalid {
  direction: ltr;
  text-align: left;
}

.firebaseui-input-invalid {
  border-color: #dd2c00;
}

.firebaseui-textfield {
  width: 100%;
}

.firebaseui-textfield.mdl-textfield .firebaseui-input {
  border-color: #0000001f;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label:after {
  background-color: #3f51b5;
}

.firebaseui-textfield-invalid.mdl-textfield .firebaseui-input {
  border-color: #dd2c00;
}

.firebaseui-textfield-invalid.mdl-textfield .firebaseui-label:after {
  background-color: #dd2c00;
}

.firebaseui-button {
  height: 36px;
  min-width: 88px;
  margin-left: 8px;
  display: inline-block;
}

.firebaseui-link {
  color: #4285f4;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none;
}

.firebaseui-link:hover {
  text-decoration: underline;
}

.firebaseui-indent {
  margin-left: 1em;
}

.firebaseui-tos {
  color: #757575;
  direction: ltr;
  text-align: left;
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 16px;
}

.firebaseui-provider-sign-in-footer > .firebaseui-tos {
  text-align: center;
}

.firebaseui-tos-list {
  text-align: right;
  list-style: none;
}

.firebaseui-inline-list-item {
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}

.firebaseui-page-provider-sign-in, .firebaseui-page-select-tenant {
  background: inherit;
}

.firebaseui-idp-list, .firebaseui-tenant-list {
  margin: 1em 0;
  padding: 0;
  list-style: none;
}

.firebaseui-idp-button, .firebaseui-tenant-button {
  direction: ltr;
  height: auto;
  max-width: 220px;
  min-height: 40px;
  text-align: left;
  width: 100%;
  padding: 8px 16px;
  font-weight: 500;
  line-height: normal;
}

.firebaseui-idp-list > .firebaseui-list-item, .firebaseui-tenant-list > .firebaseui-list-item {
  text-align: center;
  margin-bottom: 15px;
}

.firebaseui-idp-icon-wrapper {
  vertical-align: middle;
  display: table-cell;
}

.firebaseui-idp-icon {
  height: 18px;
  vertical-align: middle;
  width: 18px;
  border: none;
  display: inline-block;
}

.firebaseui-idp-favicon {
  height: 14px;
  vertical-align: middle;
  width: 14px;
  border: none;
  margin-right: 5px;
  display: inline-block;
}

.firebaseui-idp-text {
  color: #fff;
  text-transform: none;
  vertical-align: middle;
  padding-left: 16px;
  font-size: 14px;
  display: table-cell;
}

.firebaseui-idp-text.firebaseui-idp-text-long {
  display: table-cell;
}

.firebaseui-idp-text.firebaseui-idp-text-short {
  display: none;
}

@media (max-width: 268px) {
  .firebaseui-idp-text.firebaseui-idp-text-long {
    display: none;
  }

  .firebaseui-idp-text.firebaseui-idp-text-short {
    display: table-cell;
  }
}

@media (max-width: 320px) {
  .firebaseui-recaptcha-container > div > div {
    transform-origin: 0 0;
    transform: scale(.9);
  }
}

.firebaseui-idp-google > .firebaseui-idp-text {
  color: #757575;
}

[data-provider-id="yahoo.com"] > .firebaseui-idp-icon-wrapper > .firebaseui-idp-icon {
  height: 22px;
  width: 22px;
}

.firebaseui-info-bar {
  text-align: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  padding: 8px 16px;
  position: absolute;
  top: 0;
  left: 10%;
  right: 10%;
  box-shadow: 0 2px 4px #0003;
}

.firebaseui-info-bar-message {
  margin: 0;
  font-size: 12px;
}

.firebaseui-dialog {
  box-sizing: border-box;
  color: #000000de;
  height: auto;
  max-height: -moz-fit-content;
  max-height: fit-content;
  text-align: left;
  padding: 24px;
  font: 16px Roboto, arial, sans-serif;
}

.firebaseui-dialog-icon-wrapper {
  vertical-align: middle;
  display: table-cell;
}

.firebaseui-dialog-icon {
  float: left;
  height: 40px;
  width: 40px;
  margin-right: 24px;
}

.firebaseui-progress-dialog-message {
  min-height: 40px;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 400;
  display: table-cell;
}

.firebaseui-progress-dialog-loading-icon {
  height: 28px;
  width: 28px;
  margin: 6px 30px 6px 6px;
}

.firebaseui-icon-done {
  background-image: url("https://www.gstatic.com/images/icons/material/system/2x/done_googgreen_36dp.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 36px 36px;
}

.firebaseui-phone-number {
  display: flex;
}

.firebaseui-country-selector {
  color: #000000de;
  height: initial;
  width: 90px;
  background-image: url("https://www.gstatic.com/images/icons/material/system/1x/arrow_drop_down_grey600_18dp.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 18px;
  border-bottom: 1px solid #0000001f;
  border-radius: 0;
  flex-shrink: 0;
  margin: 20px 24px 20px 0;
  padding: 4px 20px 4px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.firebaseui-country-selector-flag {
  margin-right: 1ex;
  display: inline-block;
}

.firebaseui-flag {
  filter: drop-shadow(1px 1px 1px #0000008a);
  height: 14px;
  width: 24px;
  background-image: url("https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/flags_sprite_2x.png");
  background-size: 100%;
}

.firebaseui-list-box-dialog {
  max-height: 90%;
  padding: 8px 0 0;
  overflow: auto;
}

.firebaseui-list-box-actions {
  padding-bottom: 8px;
}

.firebaseui-list-box-icon-wrapper {
  vertical-align: top;
  padding-right: 24px;
  display: table-cell;
}

.firebaseui-list-box-label-wrapper {
  vertical-align: top;
  display: table-cell;
}

.firebaseui-list-box-dialog-button {
  color: #000000de;
  direction: ltr;
  height: initial;
  min-height: 48px;
  text-align: left;
  text-transform: none;
  width: 100%;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.firebaseui-phone-number-error {
  margin-left: 114px;
}

.mdl-progress.firebaseui-busy-indicator {
  height: 2px;
  width: 100%;
  position: absolute;
  top: 55px;
  left: 0;
}

.mdl-spinner.firebaseui-busy-indicator {
  direction: initial;
  height: 56px;
  width: 56px;
  margin: auto;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
}

.firebaseui-callback-indicator-container .firebaseui-busy-indicator {
  top: 0;
}

.firebaseui-callback-indicator-container {
  height: 120px;
}

.firebaseui-new-password-component {
  width: 100%;
  display: inline-block;
  position: relative;
}

.firebaseui-input-floating-button {
  height: 24px;
  width: 24px;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 20px;
  right: 0;
}

.firebaseui-input-toggle-on {
  background-image: url("https://www.gstatic.com/images/icons/material/system/1x/visibility_black_24dp.png");
}

.firebaseui-input-toggle-off {
  background-image: url("https://www.gstatic.com/images/icons/material/system/1x/visibility_off_black_24dp.png");
}

.firebaseui-input-toggle-focus {
  opacity: .87;
}

.firebaseui-input-toggle-blur {
  opacity: .38;
}

.firebaseui-recaptcha-wrapper {
  margin: 0 auto;
  padding-bottom: 8px;
  display: table;
}

.firebaseui-recaptcha-container {
  display: table-cell;
}

.firebaseui-recaptcha-error-wrapper {
  caption-side: bottom;
  display: table-caption;
}

.firebaseui-change-phone-number-link {
  display: block;
}

.firebaseui-resend-container {
  direction: ltr;
  text-align: center;
  margin: 20px 0;
}

.firebaseui-id-resend-countdown {
  color: #00000061;
}

.firebaseui-id-page-phone-sign-in-start .firebaseui-form-actions div {
  float: left;
}

@media (max-width: 480px) {
  .firebaseui-container {
    box-shadow: none;
    max-width: none;
    width: 100%;
  }

  .firebaseui-card-header {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 16px;
    padding: 16px 24px 0;
  }

  .firebaseui-title {
    padding-bottom: 16px;
  }

  .firebaseui-card-actions {
    padding-right: 24px;
  }

  .firebaseui-busy-indicator {
    top: 0;
  }
}

.mdl-textfield__label {
  margin-bottom: 0;
  font-weight: 400;
}

.firebaseui-id-page-blank, .firebaseui-id-page-spinner {
  background: inherit;
  height: 64px;
}

.firebaseui-email-sent {
  height: 64px;
  text-align: center;
  background-image: url("https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/success_status.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 64px 64px;
  margin-top: 16px;
}

.firebaseui-text-justify {
  text-align: justify;
}

.firebaseui-flag-KY {
  background-position: 0 0;
}

.firebaseui-flag-AC {
  background-position: 0 -14px;
}

.firebaseui-flag-AE {
  background-position: 0 -28px;
}

.firebaseui-flag-AF {
  background-position: 0 -42px;
}

.firebaseui-flag-AG {
  background-position: 0 -56px;
}

.firebaseui-flag-AI {
  background-position: 0 -70px;
}

.firebaseui-flag-AL {
  background-position: 0 -84px;
}

.firebaseui-flag-AM {
  background-position: 0 -98px;
}

.firebaseui-flag-AO {
  background-position: 0 -112px;
}

.firebaseui-flag-AQ {
  background-position: 0 -126px;
}

.firebaseui-flag-AR {
  background-position: 0 -140px;
}

.firebaseui-flag-AS {
  background-position: 0 -154px;
}

.firebaseui-flag-AT {
  background-position: 0 -168px;
}

.firebaseui-flag-AU {
  background-position: 0 -182px;
}

.firebaseui-flag-AW {
  background-position: 0 -196px;
}

.firebaseui-flag-AX {
  background-position: 0 -210px;
}

.firebaseui-flag-AZ {
  background-position: 0 -224px;
}

.firebaseui-flag-BA {
  background-position: 0 -238px;
}

.firebaseui-flag-BB {
  background-position: 0 -252px;
}

.firebaseui-flag-BD {
  background-position: 0 -266px;
}

.firebaseui-flag-BE {
  background-position: 0 -280px;
}

.firebaseui-flag-BF {
  background-position: 0 -294px;
}

.firebaseui-flag-BG {
  background-position: 0 -308px;
}

.firebaseui-flag-BH {
  background-position: 0 -322px;
}

.firebaseui-flag-BI {
  background-position: 0 -336px;
}

.firebaseui-flag-BJ {
  background-position: 0 -350px;
}

.firebaseui-flag-BL {
  background-position: 0 -364px;
}

.firebaseui-flag-BM {
  background-position: 0 -378px;
}

.firebaseui-flag-BN {
  background-position: 0 -392px;
}

.firebaseui-flag-BO {
  background-position: 0 -406px;
}

.firebaseui-flag-BQ {
  background-position: 0 -420px;
}

.firebaseui-flag-BR {
  background-position: 0 -434px;
}

.firebaseui-flag-BS {
  background-position: 0 -448px;
}

.firebaseui-flag-BT {
  background-position: 0 -462px;
}

.firebaseui-flag-BV {
  background-position: 0 -476px;
}

.firebaseui-flag-BW {
  background-position: 0 -490px;
}

.firebaseui-flag-BY {
  background-position: 0 -504px;
}

.firebaseui-flag-BZ {
  background-position: 0 -518px;
}

.firebaseui-flag-CA {
  background-position: 0 -532px;
}

.firebaseui-flag-CC {
  background-position: 0 -546px;
}

.firebaseui-flag-CD {
  background-position: 0 -560px;
}

.firebaseui-flag-CF {
  background-position: 0 -574px;
}

.firebaseui-flag-CG {
  background-position: 0 -588px;
}

.firebaseui-flag-CH {
  background-position: 0 -602px;
}

.firebaseui-flag-CI {
  background-position: 0 -616px;
}

.firebaseui-flag-CK {
  background-position: 0 -630px;
}

.firebaseui-flag-CL {
  background-position: 0 -644px;
}

.firebaseui-flag-CM {
  background-position: 0 -658px;
}

.firebaseui-flag-CN {
  background-position: 0 -672px;
}

.firebaseui-flag-CO {
  background-position: 0 -686px;
}

.firebaseui-flag-CP {
  background-position: 0 -700px;
}

.firebaseui-flag-CR {
  background-position: 0 -714px;
}

.firebaseui-flag-CU {
  background-position: 0 -728px;
}

.firebaseui-flag-CV {
  background-position: 0 -742px;
}

.firebaseui-flag-CW {
  background-position: 0 -756px;
}

.firebaseui-flag-CX {
  background-position: 0 -770px;
}

.firebaseui-flag-CY {
  background-position: 0 -784px;
}

.firebaseui-flag-CZ {
  background-position: 0 -798px;
}

.firebaseui-flag-DE {
  background-position: 0 -812px;
}

.firebaseui-flag-DG {
  background-position: 0 -826px;
}

.firebaseui-flag-DJ {
  background-position: 0 -840px;
}

.firebaseui-flag-DK {
  background-position: 0 -854px;
}

.firebaseui-flag-DM {
  background-position: 0 -868px;
}

.firebaseui-flag-DO {
  background-position: 0 -882px;
}

.firebaseui-flag-DZ {
  background-position: 0 -896px;
}

.firebaseui-flag-EA {
  background-position: 0 -910px;
}

.firebaseui-flag-EC {
  background-position: 0 -924px;
}

.firebaseui-flag-EE {
  background-position: 0 -938px;
}

.firebaseui-flag-EG {
  background-position: 0 -952px;
}

.firebaseui-flag-EH {
  background-position: 0 -966px;
}

.firebaseui-flag-ER {
  background-position: 0 -980px;
}

.firebaseui-flag-ES {
  background-position: 0 -994px;
}

.firebaseui-flag-ET {
  background-position: 0 -1008px;
}

.firebaseui-flag-EU {
  background-position: 0 -1022px;
}

.firebaseui-flag-FI {
  background-position: 0 -1036px;
}

.firebaseui-flag-FJ {
  background-position: 0 -1050px;
}

.firebaseui-flag-FK {
  background-position: 0 -1064px;
}

.firebaseui-flag-FM {
  background-position: 0 -1078px;
}

.firebaseui-flag-FO {
  background-position: 0 -1092px;
}

.firebaseui-flag-FR {
  background-position: 0 -1106px;
}

.firebaseui-flag-GA {
  background-position: 0 -1120px;
}

.firebaseui-flag-GB {
  background-position: 0 -1134px;
}

.firebaseui-flag-GD {
  background-position: 0 -1148px;
}

.firebaseui-flag-GE {
  background-position: 0 -1162px;
}

.firebaseui-flag-GF {
  background-position: 0 -1176px;
}

.firebaseui-flag-GG {
  background-position: 0 -1190px;
}

.firebaseui-flag-GH {
  background-position: 0 -1204px;
}

.firebaseui-flag-GI {
  background-position: 0 -1218px;
}

.firebaseui-flag-GL {
  background-position: 0 -1232px;
}

.firebaseui-flag-GM {
  background-position: 0 -1246px;
}

.firebaseui-flag-GN {
  background-position: 0 -1260px;
}

.firebaseui-flag-GP {
  background-position: 0 -1274px;
}

.firebaseui-flag-GQ {
  background-position: 0 -1288px;
}

.firebaseui-flag-GR {
  background-position: 0 -1302px;
}

.firebaseui-flag-GS {
  background-position: 0 -1316px;
}

.firebaseui-flag-GT {
  background-position: 0 -1330px;
}

.firebaseui-flag-GU {
  background-position: 0 -1344px;
}

.firebaseui-flag-GW {
  background-position: 0 -1358px;
}

.firebaseui-flag-GY {
  background-position: 0 -1372px;
}

.firebaseui-flag-HK {
  background-position: 0 -1386px;
}

.firebaseui-flag-HM {
  background-position: 0 -1400px;
}

.firebaseui-flag-HN {
  background-position: 0 -1414px;
}

.firebaseui-flag-HR {
  background-position: 0 -1428px;
}

.firebaseui-flag-HT {
  background-position: 0 -1442px;
}

.firebaseui-flag-HU {
  background-position: 0 -1456px;
}

.firebaseui-flag-IC {
  background-position: 0 -1470px;
}

.firebaseui-flag-ID {
  background-position: 0 -1484px;
}

.firebaseui-flag-IE {
  background-position: 0 -1498px;
}

.firebaseui-flag-IL {
  background-position: 0 -1512px;
}

.firebaseui-flag-IM {
  background-position: 0 -1526px;
}

.firebaseui-flag-IN {
  background-position: 0 -1540px;
}

.firebaseui-flag-IO {
  background-position: 0 -1554px;
}

.firebaseui-flag-IQ {
  background-position: 0 -1568px;
}

.firebaseui-flag-IR {
  background-position: 0 -1582px;
}

.firebaseui-flag-IS {
  background-position: 0 -1596px;
}

.firebaseui-flag-IT {
  background-position: 0 -1610px;
}

.firebaseui-flag-JE {
  background-position: 0 -1624px;
}

.firebaseui-flag-JM {
  background-position: 0 -1638px;
}

.firebaseui-flag-JO {
  background-position: 0 -1652px;
}

.firebaseui-flag-JP {
  background-position: 0 -1666px;
}

.firebaseui-flag-KE {
  background-position: 0 -1680px;
}

.firebaseui-flag-KG {
  background-position: 0 -1694px;
}

.firebaseui-flag-KH {
  background-position: 0 -1708px;
}

.firebaseui-flag-KI {
  background-position: 0 -1722px;
}

.firebaseui-flag-KM {
  background-position: 0 -1736px;
}

.firebaseui-flag-KN {
  background-position: 0 -1750px;
}

.firebaseui-flag-KP {
  background-position: 0 -1764px;
}

.firebaseui-flag-KR {
  background-position: 0 -1778px;
}

.firebaseui-flag-KW {
  background-position: 0 -1792px;
}

.firebaseui-flag-AD {
  background-position: 0 -1806px;
}

.firebaseui-flag-KZ {
  background-position: 0 -1820px;
}

.firebaseui-flag-LA {
  background-position: 0 -1834px;
}

.firebaseui-flag-LB {
  background-position: 0 -1848px;
}

.firebaseui-flag-LC {
  background-position: 0 -1862px;
}

.firebaseui-flag-LI {
  background-position: 0 -1876px;
}

.firebaseui-flag-LK {
  background-position: 0 -1890px;
}

.firebaseui-flag-LR {
  background-position: 0 -1904px;
}

.firebaseui-flag-LS {
  background-position: 0 -1918px;
}

.firebaseui-flag-LT {
  background-position: 0 -1932px;
}

.firebaseui-flag-LU {
  background-position: 0 -1946px;
}

.firebaseui-flag-LV {
  background-position: 0 -1960px;
}

.firebaseui-flag-LY {
  background-position: 0 -1974px;
}

.firebaseui-flag-MA {
  background-position: 0 -1988px;
}

.firebaseui-flag-MC {
  background-position: 0 -2002px;
}

.firebaseui-flag-MD {
  background-position: 0 -2016px;
}

.firebaseui-flag-ME {
  background-position: 0 -2030px;
}

.firebaseui-flag-MF {
  background-position: 0 -2044px;
}

.firebaseui-flag-MG {
  background-position: 0 -2058px;
}

.firebaseui-flag-MH {
  background-position: 0 -2072px;
}

.firebaseui-flag-MK {
  background-position: 0 -2086px;
}

.firebaseui-flag-ML {
  background-position: 0 -2100px;
}

.firebaseui-flag-MM {
  background-position: 0 -2114px;
}

.firebaseui-flag-MN {
  background-position: 0 -2128px;
}

.firebaseui-flag-MO {
  background-position: 0 -2142px;
}

.firebaseui-flag-MP {
  background-position: 0 -2156px;
}

.firebaseui-flag-MQ {
  background-position: 0 -2170px;
}

.firebaseui-flag-MR {
  background-position: 0 -2184px;
}

.firebaseui-flag-MS {
  background-position: 0 -2198px;
}

.firebaseui-flag-MT {
  background-position: 0 -2212px;
}

.firebaseui-flag-MU {
  background-position: 0 -2226px;
}

.firebaseui-flag-MV {
  background-position: 0 -2240px;
}

.firebaseui-flag-MW {
  background-position: 0 -2254px;
}

.firebaseui-flag-MX {
  background-position: 0 -2268px;
}

.firebaseui-flag-MY {
  background-position: 0 -2282px;
}

.firebaseui-flag-MZ {
  background-position: 0 -2296px;
}

.firebaseui-flag-NA {
  background-position: 0 -2310px;
}

.firebaseui-flag-NC {
  background-position: 0 -2324px;
}

.firebaseui-flag-NE {
  background-position: 0 -2338px;
}

.firebaseui-flag-NF {
  background-position: 0 -2352px;
}

.firebaseui-flag-NG {
  background-position: 0 -2366px;
}

.firebaseui-flag-NI {
  background-position: 0 -2380px;
}

.firebaseui-flag-NL {
  background-position: 0 -2394px;
}

.firebaseui-flag-NO {
  background-position: 0 -2408px;
}

.firebaseui-flag-NP {
  background-position: 0 -2422px;
}

.firebaseui-flag-NR {
  background-position: 0 -2436px;
}

.firebaseui-flag-NU {
  background-position: 0 -2450px;
}

.firebaseui-flag-NZ {
  background-position: 0 -2464px;
}

.firebaseui-flag-OM {
  background-position: 0 -2478px;
}

.firebaseui-flag-PA {
  background-position: 0 -2492px;
}

.firebaseui-flag-PE {
  background-position: 0 -2506px;
}

.firebaseui-flag-PF {
  background-position: 0 -2520px;
}

.firebaseui-flag-PG {
  background-position: 0 -2534px;
}

.firebaseui-flag-PH {
  background-position: 0 -2548px;
}

.firebaseui-flag-PK {
  background-position: 0 -2562px;
}

.firebaseui-flag-PL {
  background-position: 0 -2576px;
}

.firebaseui-flag-PM {
  background-position: 0 -2590px;
}

.firebaseui-flag-PN {
  background-position: 0 -2604px;
}

.firebaseui-flag-PR {
  background-position: 0 -2618px;
}

.firebaseui-flag-PS {
  background-position: 0 -2632px;
}

.firebaseui-flag-PT {
  background-position: 0 -2646px;
}

.firebaseui-flag-PW {
  background-position: 0 -2660px;
}

.firebaseui-flag-PY {
  background-position: 0 -2674px;
}

.firebaseui-flag-QA {
  background-position: 0 -2688px;
}

.firebaseui-flag-RE {
  background-position: 0 -2702px;
}

.firebaseui-flag-RO {
  background-position: 0 -2716px;
}

.firebaseui-flag-RS {
  background-position: 0 -2730px;
}

.firebaseui-flag-RU {
  background-position: 0 -2744px;
}

.firebaseui-flag-RW {
  background-position: 0 -2758px;
}

.firebaseui-flag-SA {
  background-position: 0 -2772px;
}

.firebaseui-flag-SB {
  background-position: 0 -2786px;
}

.firebaseui-flag-SC {
  background-position: 0 -2800px;
}

.firebaseui-flag-SD {
  background-position: 0 -2814px;
}

.firebaseui-flag-SE {
  background-position: 0 -2828px;
}

.firebaseui-flag-SG {
  background-position: 0 -2842px;
}

.firebaseui-flag-SH {
  background-position: 0 -2856px;
}

.firebaseui-flag-SI {
  background-position: 0 -2870px;
}

.firebaseui-flag-SJ {
  background-position: 0 -2884px;
}

.firebaseui-flag-SK {
  background-position: 0 -2898px;
}

.firebaseui-flag-SL {
  background-position: 0 -2912px;
}

.firebaseui-flag-SM {
  background-position: 0 -2926px;
}

.firebaseui-flag-SN {
  background-position: 0 -2940px;
}

.firebaseui-flag-SO {
  background-position: 0 -2954px;
}

.firebaseui-flag-SR {
  background-position: 0 -2968px;
}

.firebaseui-flag-SS {
  background-position: 0 -2982px;
}

.firebaseui-flag-ST {
  background-position: 0 -2996px;
}

.firebaseui-flag-SV {
  background-position: 0 -3010px;
}

.firebaseui-flag-SX {
  background-position: 0 -3024px;
}

.firebaseui-flag-SY {
  background-position: 0 -3038px;
}

.firebaseui-flag-SZ {
  background-position: 0 -3052px;
}

.firebaseui-flag-TA {
  background-position: 0 -3066px;
}

.firebaseui-flag-TC {
  background-position: 0 -3080px;
}

.firebaseui-flag-TD {
  background-position: 0 -3094px;
}

.firebaseui-flag-TF {
  background-position: 0 -3108px;
}

.firebaseui-flag-TG {
  background-position: 0 -3122px;
}

.firebaseui-flag-TH {
  background-position: 0 -3136px;
}

.firebaseui-flag-TJ {
  background-position: 0 -3150px;
}

.firebaseui-flag-TK {
  background-position: 0 -3164px;
}

.firebaseui-flag-TL {
  background-position: 0 -3178px;
}

.firebaseui-flag-TM {
  background-position: 0 -3192px;
}

.firebaseui-flag-TN {
  background-position: 0 -3206px;
}

.firebaseui-flag-TO {
  background-position: 0 -3220px;
}

.firebaseui-flag-TR {
  background-position: 0 -3234px;
}

.firebaseui-flag-TT {
  background-position: 0 -3248px;
}

.firebaseui-flag-TV {
  background-position: 0 -3262px;
}

.firebaseui-flag-TW {
  background-position: 0 -3276px;
}

.firebaseui-flag-TZ {
  background-position: 0 -3290px;
}

.firebaseui-flag-UA {
  background-position: 0 -3304px;
}

.firebaseui-flag-UG {
  background-position: 0 -3318px;
}

.firebaseui-flag-UM {
  background-position: 0 -3332px;
}

.firebaseui-flag-UN {
  background-position: 0 -3346px;
}

.firebaseui-flag-US {
  background-position: 0 -3360px;
}

.firebaseui-flag-UY {
  background-position: 0 -3374px;
}

.firebaseui-flag-UZ {
  background-position: 0 -3388px;
}

.firebaseui-flag-VA {
  background-position: 0 -3402px;
}

.firebaseui-flag-VC {
  background-position: 0 -3416px;
}

.firebaseui-flag-VE {
  background-position: 0 -3430px;
}

.firebaseui-flag-VG {
  background-position: 0 -3444px;
}

.firebaseui-flag-VI {
  background-position: 0 -3458px;
}

.firebaseui-flag-VN {
  background-position: 0 -3472px;
}

.firebaseui-flag-VU {
  background-position: 0 -3486px;
}

.firebaseui-flag-WF {
  background-position: 0 -3500px;
}

.firebaseui-flag-WS {
  background-position: 0 -3514px;
}

.firebaseui-flag-XK {
  background-position: 0 -3528px;
}

.firebaseui-flag-YE {
  background-position: 0 -3542px;
}

.firebaseui-flag-YT {
  background-position: 0 -3556px;
}

.firebaseui-flag-ZA {
  background-position: 0 -3570px;
}

.firebaseui-flag-ZM {
  background-position: 0 -3584px;
}

.firebaseui-flag-ZW {
  background-position: 0 -3598px;
}

/*# sourceMappingURL=index.e5023412.css.map */
